<template>
  <v-card width="100%" tile height="100%" class="pa-0 ma-0">
    <v-container fluid class="pa-0" fill-height>
      <v-row align="stretch" class="fill-height">
        <v-col cols="12" md="5" class="text-center pa-0" order="2" order-md="2" v-if="!$vuetify.breakpoint.mobile">
          <v-card color="primary" tile height="100%" class="pa-10 mx-auto" flat dark>
            <v-row class="fill-height ma-0" justify="center">
              <v-col>
                <h1>Gestão de Privacidade e Segurança de Dados</h1>
                <h3>
                  Adeque sua empresa à Lei Geral de Proteção de Dados (LGPD)
                </h3>
                <img src="@/assets/lgpd_digisystem_ibm.png" style="max-width:100%;" />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col>
        <!-- <v-col cols="12" md="7" order="2" order-md="2"> -->
          <v-row class="fill-height" justify="center">
            <v-col>
              <div class="px-10">
                <div class="text-center pa-5">
                  <img src="@/assets/logo.png" width="400px" style="max-width:80%;" />
                </div>
                <v-row justify="center" >
                  <v-col cols="12" xs="12" sm="12" md="12" lg="10" xl="10" xxl="10">
                    <v-card-title class="text-center">
                      <h2 class="headline">Recuperação de Senha</h2>
                    </v-card-title>
            
                    <v-card-text>
                      <v-form ref="formRegistrar" v-model="validRegistrar">
                        <b>Digite seu e-mail</b>
                        <v-text-field
                          v-model="user.email"
                          placeholder="Email"                
                          outlined
                          tile
                          dense
                          :rules="emailRules"                
                          class="my-2"
                        ></v-text-field>
                      </v-form>
                      <v-btn
                        depressed
                        block
                        large
                        rounded
                        dark
                        color="primary"
                        @click="sendRecoveryEmail"
                      >
                        Enviar Email de Recuperação
                      </v-btn>
                    </v-card-text>
            
                    <v-card-actions class="justify-center">
                    </v-card-actions>
                  </v-col>
                </v-row>     
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
  
<script>
  import axios from "axios";
  import { baseApiUrl } from "@/global";
  
  export default {
    name: "PasswordRecovery",
    data: () => ({
      user: {
        email: "",
      },
      emailRules: [
        (v) => !!v || "O email é necessário",
        (v) => /.+@.+\..+/.test(v) || "É preciso um email válido",
      ],
    }),
    methods: {
      sendRecoveryEmail() {
        const url = `${baseApiUrl}/passwordRecovery`; //passwordRecovery
        axios
          .post(url, { email: this.user.email })
          .then(() => {
            this.$store.dispatch('setErrorInfo', {
              open: true,
              text: 'Foi enviado para seu e-mail um link para alterar sua senha de acesso.',
              route: '/login'
            })
          })
          .catch(error => {
            if (error) {
              // window.alert("O email não foi encontrado. Verifique se o endereço de email está correto.");
              this.$store.dispatch('setErrorInfo', {
                open: true,
                text: 'O e-mail não foi encontrado. Verifique se o endereço de e-mail está correto.',
                button: 'Ok!',
              })
            } else {
              window.alert("Ocorreu um erro ao enviar o email de recuperação.");
            }
          });
      },
    },
  };
  </script>
  
  <style>
  
  </style>
  